import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () => import('./components/MainContents.vue')
    },
    {
        path: '/inquiry',
        component: () => import('./components/ContactForm.vue')
    },
    {
        path: '/request',
        component: () => import('./components/RequestForm.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
